import { CloudUpload } from 'tabler-icons-react';
import { Tooltip } from '@material-ui/core';
import { Group, Text, useMantineTheme, MantineTheme } from '@mantine/core';
import { Upload, Photo, X, Icon as TablerIcon } from 'tabler-icons-react';
import { Dropzone, DropzoneStatus, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useRef, useState } from 'react';
import { handleKeyUp } from '../../utils';
import Input from './Input';
import * as styles from './PhotoUpload.module.css';
import StorageContext from '../../contexts/StorageContext';
import { useDispatch, useSelector } from '../../contexts/ResumeContext';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForceUpdate, randomId } from '@mantine/hooks';
import { wfetch } from '../../utils';

const PhotoUpload = () => {
  const { t } = useTranslation();
  const { uploadPhotograph } = useContext(StorageContext);
  const dispatch = useDispatch();

  const force = useForceUpdate();

  const [stateValue, setStateValue] = useState(useSelector("profile.photograph", ''));

  const [ loading, setLoading ] = useState(false);

  const theme = useMantineTheme();

  function getIconColor(status, theme) {
    return status.accepted
      ? theme.colors[theme.green][theme.colorScheme === 'dark' ? 4 : 6]
      : status.rejected
      ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
      : theme.colorScheme === 'dark'
      ? theme.colors.red[0]
      : theme.colors.red[7];
  }

  const uploadImage = async (files) => {
    setLoading(true);

    showNotification({
      id: 'photo-upload',
      autoClose: 30000,
      title: "Saving Image",
      message: 'Please wait while we save your image',
      loading: true, 
    });

    var id = "";

    if(typeof window !== "undefined") {
      id = window.location.href.split("/")[5];
    }

    id = randomId();
    id = id.replace("mantine-", "");

    var payload = {id: id, data: "", ext: "", mime: ""};

    const reader = new FileReader();

    reader.addEventListener("load", function () {
      // convert image file to base64 strin
      const data = reader.result;

      payload["data"] = data.split('base64,')[1];
      payload["documentId"] = id;
  
      payload["mime"] = files[0].type;
      payload['ext'] = files[0].type.split('/')[1];

      wfetch( "https://document.boardsi.com/api/v3/board-document/upload/", { method: 'POST', body: JSON.stringify(payload)} ).then((results)=>{
        results.json().then((pictureUrl)=>{
          if(pictureUrl.includes("cdn.boardsi.com")) {
            if(typeof window !== "undefined") {
              window.document.querySelector("input[name='photograph']").setAttribute("value", "");
              window.document.querySelector("input[name='photograph']").setAttribute("value", pictureUrl);
              window.document.querySelector("input[name='photograph']").value = pictureUrl;
              setStateValue(pictureUrl);

                dispatch({
                  type: 'on_input',
                  payload: {
                    path: "profile.photograph",
                    value: pictureUrl,
                  },
                });

              updateNotification({
                id: 'photo-upload',
                autoClose: 6000,
                title: "Image Saved!",
                message: 'Your image has been saved and added to your board document.',
                loading: false,
                color: "green"
              });

              force();
            }
          } else {
            updateNotification({
              id: 'photo-upload',
              autoClose: 6000,
              title: "Error",
              message: 'We were unable to save your image. Please try again later.',
              loading: false,
              color: "red"
            });
          }

          setTimeout(()=>{
            setLoading(false);
          }, 1000);
        })
      });

    }, false);

    reader.readAsDataURL(files[0]);

    /*
    var oranges = new FormData();

    oranges.append("file", files[0]);
    oranges.append("documentId", id);

    oranges.append("mime", files[0].type)
    oranges.append('ext', files[0].type.split('/')[1])
    */
  


  }

  function ImageUploadIcon({
    status,
    ...props
  }) {
    if (status.accepted) {
      return <Upload {...props} />;
    }
  
    if (status.rejected) {
      return <X {...props} />;
    }
  
    return <Photo {...props} />;
  }
  
  const dropzoneChildren = (status, theme) => (
      loading ? (<></>) : (<ImageUploadIcon status={status} style={{ color: getIconColor(status, theme) }} size={18} />)
  );


  return (
    <div className="flex items-center">
      <div className={styles.circle}>
      <Tooltip
        title={t('builder.tooltips.uploadPhotograph')}
        placement="right-start"
      >
            <Dropzone loading={loading}
              style={{padding: "25px", borderRadius: "100px"}}
              onDrop={(files)=>{uploadImage(files)}}
              onReject={(files) => console.log('rejected files', files)}
              maxSize={5 * 1024 ** 2}
              accept={IMAGE_MIME_TYPE}
            >
              {(status) => dropzoneChildren(status, theme)}
            </Dropzone>
      </Tooltip>
      </div>

      <Input
        value={stateValue}
        name="photograph"
        label={t('builder.profile.photograph')}
        className="pl-6 w-full"
        path="profile.photograph"
      />
    </div>
  );
};

export default memo(PhotoUpload);
