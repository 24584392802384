import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";
import React, { memo, useContext } from 'react';
import { navigate } from 'gatsby';
import { Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
//import { BsBoxArrowRight, BsBoxArrowLeft } from 'react-icons/bs';
import { ArrowAutofitLeft, ArrowAutofitRight } from 'tabler-icons-react';
import { VscPreview } from 'react-icons/vsc';
import * as styles from './RightNavbar.module.css';
import SectionIcon from '../../shared/SectionIcon';
import SyncIndicator from './SyncIndicator';
import sections from '../../../data/rightSections';
import SettingsContext from '../../../contexts/SettingsContext';

const handleGotoApp = () => {
  if ( typeof window !== `undefined` ) {
    const currentLocation = window.location.href.split( "/" );
    const id = currentLocation[ ( currentLocation.length - 1 ) ];

    navigate( `/r/${id}/` );
  }
}

const SideBarToggleIcon = ({ className }) => {
  const { isSideBarOpen, setSideBarToggle } = useContext(SettingsContext);
  const ToggleIcon = isSideBarOpen ? ArrowAutofitRight : ArrowAutofitLeft;
  return (
    <div
      className={className} style={{backgroundColor: "transparent !important", cursor: "pointer !important"}}
      onClick={() => setSideBarToggle(!isSideBarOpen)}
    >
      <ToggleIcon style={{backgroundColor: "transparent !important"}} size="18px" />
    </div>
  );
};

const ViewBoardDocumentIcon = ( { className } ) => {
  const ToggleIcon = VscPreview;

  return (
      <button style={{backgroundColor: "transparent !important"}}
        onClick={handleGotoApp}
        className={styles.iconButton}>
        <ToggleIcon size="18px" />
      </button>
  );
};

const RightNavbar = () => {
  
  const container = {
    hidden: { opacity: 1, scale: 1 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: .15,
        staggerChildren: .15,
      }
    }
  };

  const item = {
    hidden: { y: 15, opacity: 0 },
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: i * .1,
        duration: .2,
        type: "spring",
        bounce: .6,
        stiffness: 300,
        damping: 150,
      }
    } )
  };

  return (
  <div className={styles.container}>

    <motion.div layout initial="hidden" animate="show" variants={container} className="grid grid-cols-1 gap-4 text-primary-500">
      <AnimatePresence>
        <LayoutGroup>
          {sections.map( ( x, i, a ) => (
            <motion.div layout key={( i + 2 )} custom={i} initial="hidden" animate="show" variants={item} style={{ marginBottom: ".5rem", marginTop: ".5rem" }}>
              <SectionIcon
                key={x.id}
                section={x}
                containerId="RightSidebar"
                tooltipPlacement="left"
              />
            </motion.div>
          ) )}
          <motion.div initial="hidden" animate="show" variants={item} style={{ marginBottom: ".5rem", marginTop: ".5rem" }}>
            <ViewBoardDocumentIcon className="" />
          </motion.div>
        </LayoutGroup>
      </AnimatePresence>
    </motion.div>


      <SideBarToggleIcon className="mt-auto cursor-pointer" />

    <hr className=" my-6" />

    <Tooltip
      label="Save Status"
      position="left"
      openDelay={20}
      closeDelay={350}
      transition="pop"
      transitionDuration="100"
      transitionTimingFunction="ease-in-out"
      gutter={10}
      radius="sm"
    >
      <SyncIndicator />
      </Tooltip>
  </div>
)};

export default memo(RightNavbar);
